.blog-outer-conatiner{
    margin-top: 13vh;
    padding: 20px;
}
.blog-inner-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.blog-create-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
}
.blog-create-container img{
    width: 60px;
    height: 50px;
}
.prev-next-btn{
    padding: 0px 30px;
    font-size: 30px;
    border: none;
    background: transparent;
    cursor: pointer;
}
.prev-next-btn.disabled{
    cursor: auto;
}

@media(max-width:750px){
    .blog-create-container .post-btn{
        padding: 10px 15px;
        font-size: 14px;
    }
}
@media(max-width:500px){
    .blog-create-container .post-btn{
        padding: 5px 5px;
    }
}