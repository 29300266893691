.comment-container{
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.3);
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.comment-container p{
    padding: 5px 0px;
}