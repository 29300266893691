.quant-container{
    width: 100%;
    margin-top: 13vh;
    height: 87vh;
}
@media(max-width:750px){
    .quant-container .container{
        margin: 2%;
    }
    .quant-container #left,
    .quant-container #right{
        width: 100%;
    }
}