.ourteam-container{
    margin-top: 13vh;
    padding: 8% 4%;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.member-container{
    display: flex;
    align-items: center;
    gap: 15%;
}
.member-imgbx{
    min-width: 40%;
    height: 900px;
    position: relative;
}
.member-imgbx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.member-content{
    min-width: 45%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.member-content p{
    text-align: justify;
}
.member-content p:first-child{
    font-family: 'Alata', sans-serif;
    font-size: 26px;
}
.member-content p:last-child{
    font-family: Tahoma;
    font-size: 14px;
    font-weight: lighter;
}

@media(max-width:750px){
    .member-container{
        align-items: center;
        gap: 5%;
    }
    .member-imgbx{
        min-width: 55%;
        height: 400px;
    }
    .member-content{
        min-width: 40%;
        gap: 40px;
    }
    .member-content p:first-child{
        font-size: 22px;
    }
}
@media(max-width:550px){
    .member-container{
        flex-direction: column;
        gap: 30px;
    }
    .member-imgbx{
        min-width: 100%;
        height:500px;
    }
}