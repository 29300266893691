.blog-container{
    width: 42%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 30px;
}
.blog-imgbx{
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
    cursor: pointer;
}
.blog-imgbx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s ease-out;
}
.blog-container:hover .blog-imgbx img{
    transform: scale(1.1);
}
.post-title{
    font-family: 'Alata', sans-serif;
    font-size: 35px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.auth-date{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 200;
}
.post-descr{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.blog-container a{
    font-family: 'Poppins', sans-serif;
    color: rgb(235, 8, 10);
    font-size: 14px;
    font-weight: 300;
}

@media(max-width:750px){
    .blog-container{
        width: 100%;
        margin: 10px;
    }
    .post-title{
        font-size: 30px;
    }
}
@media(max-width:500px){
    .post-title{
        font-size: 22px;
    }
    .post-descr{
        font-size: 13px;
    }
}