.market-container{   
    margin: 0 26px;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.marketSummary-worldMarket{
    height: auto;
    display: flex;
    justify-content: space-between;
}
@media(max-width:550px){
    .market-container{
        margin: 0 10px 0 12px;
        margin-top: 13vh;
    }
    .marketSummary-worldMarket{
        flex-direction: column;
    }
}