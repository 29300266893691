.about-container{
    margin-top: 13vh;
    min-height: calc(100vh - 130px);
    display: flex;
    padding: 3% 4%;
    gap: 30px
}
.about-contentbx{
    min-width: 46%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.about-contentbx p{
    font-family: Tahoma; 
}
.about-contentbx p:nth-of-type(1){
    font-family: 'Alata', sans-serif;
    font-size: 44px;
}
.about-contentbx p:nth-of-type(2) span{
    font-weight: bolder;
}
.about-contentbx p:nth-of-type(2){
    font-size: 16px;
    text-align: justify;
}
.about-contentbx p:last-of-type{
    font-size: 14px;
}
.about-contentbx p:last-of-type span{
    font-style: italic;
    line-height: 2rem;
}
.about-imgbx{
    min-width: 45%;
    /* min-height: 100%; */
    position: relative;
}
.about-imgbx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width:750px){
    .about-container{
        padding: 2%;
        gap: 15px;
        align-items: center;
    }
    .about-contentbx{
        min-width: 40%;
    }
    .about-contentbx p:nth-of-type(1){
        font-size: 32px;
    }
    .about-imgbx{
        min-width: 50%;
        height: 500px;
    }
}
@media(max-width:550px){
    .about-container{
        flex-direction: column-reverse;
        padding: 8% 4%;
        gap: 50px;
    }
    .about-imgbx{
        min-width: 100%;
        height: 500px;
    }
}