.createpost-container{
    margin-top: 13vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 3px;
}
.createpost-container .inputBox{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    width: 70%;
    height: 70px;
}
.createpost-container .inputBox.txtarea{
    height: 250px !important;
}
.createpost-container .inputBox input,
.createpost-container .inputBox textarea{
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding: 5px;
}
.create-post-btn{
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    background: #eb080a;
    border: none;
    margin-top: 20px;
    text-decoration: none;
    cursor: pointer;
}

@media(max-width:750px){
    .createpost-container .inputBox{
        width: 100%;
    }
}
@media(max-width:500px){
    .createpost-container{
        padding: 20px;
    }
    .createpost-container .inputBox{
        width: 100%;
    }
}