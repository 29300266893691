.footer-container{
    width: 100%;
    height: 130px;
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    padding: 10px 60px;
    transition: all .5s;
}
.logo-address{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;
}
.logo-address img{
    width: 110px;
    height: 40px;
    margin-bottom: 5px;
}
.logo-address span{
    font-size: 12px;
    color: #16a66a;
}
.footer-container a{
    text-decoration: none;
    color: #fff;
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 400;
}
.offerings{
    margin-right: 14%;
}
.offerings h6{
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 400;
}
.offerings p{
    font-family: 'Roboto Slab', serif;
    font-size: 12px;
    font-weight: 400;
    margin: 10px 0;
}

@media(max-width:650px){
    .footer-container{
        height: auto;
        flex-direction: column;
        gap: 50px;
        padding: 30px 20px;
    } 
}