.insight-submenu-container{
    width: 100%;
    min-height: calc(100vh - 13vh - 130px);
}
.submenu-heading{
    display: flex;
    align-items: center;
    padding: 1% 3%;
    /* padding-top: 3%; */
    margin-top: 13vh;
    gap: 10px;
}
.submenu-heading img{
    width: 90px;
    height: 90px;
}
.submenu-heading p{
    font-family: 'Roboto Slab', serif;
    font-size: 48px;
    font-weight: 600;
}
.submneu-content{
    padding: 20px 3%;
    font-family: Tahoma; 
    font-size: 14px;
    text-align: justify;
}
.offering-content{
    font-size: 16px;
}
.backtesting{
    width: 45%;
    float: right; 
    margin-left: 20px;   
}
.simulation{
    width: 45%;
    float: left;
    margin: 20px 20px 20px 0;
}
.predict{
    width: 45%;
    float: right;
    margin: 20px 0 10px 20px;
}
.quant{
    width: 10vw;
    height: 87vh;
    margin-top: 13vh;
}

@media(max-width:550px){
    .backtesting,
    .simulation,
    .predict{
        width: 60%;
    }
    .submenu-heading p{
        font-size: 30px;
    }
}