.singlepost-conatiner{
    width: 100%;
    margin-top: 13vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.singlepost-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.singlepost-head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.singlepost-head img{
    width: 60px;
    height: 50px;
}
.singlepost-auth-date{
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 300;
}
.singlepost-title{
    font-family: 'Alata', sans-serif;
    font-size: 64px;
    text-align: center;
    line-height: 1.1em;
}
.singlepost-imgbx{
    position: relative;
    width: 70%;
    height: 450px;
    overflow: hidden;
    cursor: pointer;
}
.singlepost-imgbx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.singlepost-desc{
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    text-align: justify;
}
.older-newer{
    display:flex;
    width:100%;
    position:relative;
}
.older-newer a{
    color:rgba(0,0,0,.4);
    position: absolute;
}
.facebook-provider{
    margin-top: 60px;
    width: 100%;
}
.comment-outer-container{
    width:80%;
    margin-top:50px;
}

.createcomment-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3px;
    /* background: cyan; */
}
.login-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.createcomment-container .inputBox{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    width: 80%;
    height: 70px;
}
.createcomment-container .inputBox.txtarea{
    height: 150px !important;
}
.createcomment-container .inputBox input,
.createcomment-container .inputBox textarea{
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding: 5px;
}

@media(max-width:800px){
    .singlepost-conatiner{
        padding: 20px;
    }
    .singlepost-imgbx{
        width: 100%;
    }
}
@media(max-width:550px){
    .comment-outer-container{
        width: 100%;
    }
    .createcomment-container{
        padding: 0px;
        padding-top: 20px;
    }
    .createcomment-container .inputBox{
        width: 100%;
    }
}