.navBar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 13vh;
    padding: 1% 5%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}
.nav-menu{
    display: flex;
    gap: 50px;
}
.navBar ul{
    list-style: none;
}
.nav-menu > ul{
    display: flex;
    gap: 30px;
}
/* .nav-menu > ul >li{
    margin: 0px 30px;
} */
.navBar ul li{
    position: relative;
}
.navBar ul li a{
    position: relative;
    display: block;
    padding: 12px 0px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: .5px;
    text-align: right;
}
.nav-menu > ul > li > a:after{
    content: '';
    position: absolute;
    display: block;
    margin-top: 5px;
    height: 2px;
    width: 0%;
    background-color: #fff;
    transition: width .3s ease;
}
.nav-menu > ul > li > a.active:after,
.nav-menu > ul > li > a:hover:after{
    width: 100%;
}
.blog{
    text-decoration: none;
    color: #fff;
    padding: 12px 35px;
    background-color: #eb080a;
    font-size: 17px;
    font-weight: 500;
}
.navBar .offering-menu{
    position: absolute;
    min-width: 160px;
    padding: 0px;
    background-color: #000;
    animation: swipedown .5s 1;
    /* transform: translateY(-20px); */
    /* transition: 'all .5s'    */
    /* visibility: hidden;
    opacity: 0; */
}
@keyframes swipedown{
    0%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
    }
}
/* .navBar .offering-menu.show{
    transform: translateY(0px);
    transition: all .5s;
} */
/* .navBar .main-menu > li:hover > ul{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: all .5s;
} */
.navBar .offering-menu li a{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
    /* line-height: .5rem; */
    border-bottom: 1px solid rgba(255,255,255,.5);
}
.offering-sub-menu{
    position: absolute;
    left: 100%;
    top: 0%;
    min-width: 165px;
    padding: 0;
    background-color: #000;
    animation: swiperight .5s 1;
    /* visibility: hidden;
    opacity: 0;*/
    /* transform: translateX(0px); */
}
@keyframes swiperight{
    0%{
        transform: translateX(-20px);
    }
    100%{
        transform: translateX(0px);
    }
}
/* .navBar .offering-menu > li:hover > ul{
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
    transition: all .5s;
} */

@media(max-width:750px){
    .nav-menu{
        display: none;
    }
    .nav-menu.active{
        width: 100%;
        height: calc(100% - 65px);
        position: fixed;
        left: 0;
        top: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #000;
        overflow-y: scroll;
        padding: 20px;
        transition: all 1s;
    }
    .nav-menu.active ul{
        flex-direction: column;
        gap: 0px;
        /* width: 150px; */
    }
    .nav-menu.active ul li a{
        text-align: center;
    }
    .nav-menu.active > ul > li > a:after{
        display: none;
    }
    .nav-menu.active .offering-menu,
    .nav-menu.active .offering-sub-menu{
        position: relative;
        /* max-width: 75px; */
    }
    .nav-menu.active .offering-sub-menu{
        left: 0;
    }
    .temp{
        position: absolute;
        top: 5px;
        left: 180px;
        width: 20px;
        height: 20px;
        /* colo: #fff; */
        /* display: flex; */
        background: url(../../images/dropdown.png);
        /* background-color: #000; */
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        z-index: 10000;
        transition: all .5s ease-in-out;
    }
    .main-menu > li > .temp{
        /* position: relative; */
        top: 13px;
        left: 180px;
    }
    .temp.active{
        transform: rotate(180deg);
    }
    /* .nav-menu.active .offering-menu li{
        max-width: 100px;
    } */
    /* .nav-menu.active .main-menu > li:hover > ul{
        position: relative;
        display: flex;
        max-width: 75px;
    }
    .nav-menu.active .offering-menu > li:hover > ul{
        position: relative;
        display: flex;
        left: 0%;
    } */
    .menuToggle{
        position: relative;
        width: 40px;
        height: 40px;
        background: url(../../images/menu.PNG);
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: .3s;
    }
    .menuToggle.active{
        background: url(../../images/close.PNG);
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
    }
}