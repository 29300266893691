.backgroundImg{
    width: 100wh;
    height: 100vh;
    background: url(../../images/home-background.jpg);
    background-size:cover;
    background-position: center 55%;
}
.textarea{
    position: absolute;
    bottom: 10%;
    color: #fff;
    margin: 0px 60px;
    font-family: 'Alata', sans-serif;
}
.textarea p:nth-child(1){
    font-size: 90px;
    font-weight: 500;
    color: #16a873;
}
.textarea p:nth-child(2){
    font-size: 63px;
    font-weight: 500;
}
.textarea p span{
    color: #a96f18;
}
.about-us{
    margin: 60px 60px;
    margin-bottom: 80px;
}
.about-us p{
    font-size: 44px;
    font-weight: 400;
    margin-bottom: 60px;
    font-family: 'Roboto', sans-serif;
}
.btn{
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    padding: 18px 32px;
    background: #eb080a;
    margin-bottom: 100px;
}
.page-conatiner{
    width: 100wh;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: #000;
    padding: 100px 60px;
}
.repeat-container{
    width: 100%;
    height: auto;
    gap: 10%;
    display: flex;
    align-items: center;
}
.repeat-container .imgBx{
    min-width: 45%;
    min-height: 530px;
    position: relative;
}
.repeat-container .imgBx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.text-Content{
    max-width: 50%;
    height: auto;
    margin-top: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    font-family: 'Alata', sans-serif;
}
.text-Content p:nth-child(1){
    color: #01a7a3;
    font-size: 35px;
    font-weight: 500;
}
.text-Content p:nth-child(2){
    color: #a73801;
    font-size: 24px;
    font-weight: 700;
}
.text-Content p:nth-child(3){
    color: #01a7a3;
    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;
    padding: 50px 0;
}
.portfolio{
    /* width: 100vw; */
    margin: 60px 60px;
}
.portfolio h4{
    font-family: 'Alata', sans-serif;
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 10px;
}
.portfolio p{
    font-size: 16px;
    font-family: 'Roboto Slab', serif;
    text-align:justify;
    margin-bottom: 50px;
}
.portfolio .imageBox{
    /* width: 100%; */
    height: 90vh;
    position: relative;
}
.portfolio .imageBox img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contact{
    width: 100wh;
    margin: 100px 50px;
    display: flex;
}
.contact-details{
    min-width: 48%;
    font-family: 'Roboto Slab', serif;
    display: flex;
    flex-direction: column;
    gap:30px;
}
.contact-heading{
    font-family: 'Alata', sans-serif;
    font-size: 44px;
}
.contact-details p{   
    font-size: 14px;
    font-weight: 300;
}
.contact-details h5{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}
.contact-form{
    position: relative;
    min-width: 48%;
    margin-top: 50px;
}
.row100{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}
.row100 .col{
    position: relative;
    width: 100%;
    padding: 0 10px;
    margin: 0px 0 20px;
}
.row100 .col .inputBox{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 70px;
}
.inputBox.txtarea{
    height: 100px !important;
    margin-bottom: 20px;
}
.row100 .col .inputBox input,
.row100 .col .inputBox textarea{
    width: 100%;
    height: 100%;
    font-size: 18px;
}
.submit-btn{
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 15px 100px;
    background: #eb080a;
    margin: 0px 10px;
}

@media(max-width:1030px){
    .textarea p:nth-child(2) {
        font-size: 43px;
    }
    .page-conatiner{
        padding: 50px;
    }
    .portfolio h4{
        font-size: 32px;
    }
    .contact-heading{
        font-size: 32px;
    }
}
@media(max-width:810px){
    .about-us {
        margin: 60px 20px;
    }
    .about-us p {
        font-size: 35px;
    }
    .text-Content{
        margin-top: 2%;
    }
    .btn {
        margin-bottom: 30px;
    }
    .contact{
        margin: 70px 30px;
    }
    .contact-form {
        width: 100%;
    }
}
@media(max-width:750px){
    .textarea {
        margin: 0px 10px;
    }
    .page-conatiner{
        padding: 50px 50px;
    }
    .repeat-container{
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        gap: 30px;
    }
    .column-reverse{
        flex-direction: column-reverse;
    }
    .repeat-container .imgBx {
        min-width: 100%;
        min-height: 60vh;
    }
    .text-Content{
        max-width: 100%;
        margin-top: 5px;
    }
    .text-Content p{
        padding: 20px 0px;
    }
    .portfolio {
        margin: 60px 20px;
    }
    .portfolio h4 {
        margin-bottom: 30px;
    }
    .portfolio p {
        margin-bottom: 50px;
    }
    .contact{
        flex-direction: column;
        margin: 20px;
    }
    .contact-form {
        margin: 50px 0;
    }
    .row100 .col {
        padding: 0px 10px 0px 0px;
    }
    .submit-btn{
        padding: 15px 30px;
        margin: 0px;
    }
}
@media(max-width:550px){
    .textarea p:nth-child(1) {
        font-size: 62px;
    }
    .textarea p:nth-child(2){
        font-size: 28px;
    }
    .about-us p {
        font-size: 28px;
    }
    .page-conatiner{
        padding: 30px 20px;
    }
    .repeat-container .imgBx {
        min-height: 40vh;
    }
    .text-Content p{
        padding: 0px 0px;
    }
    .text-Content p:nth-child(3){
        padding: 20px 0px;
    }
    .portfolio .imageBox{
        min-height: 70vh;
    }
}