.topBar{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    visibility: hidden;
    transition: visiblity 1s ease-out;
}
.topBar.sticky{
    visibility: visible;
}